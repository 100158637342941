import React from 'react';
import {Button, makeStyles} from '@material-ui/core/index';


const useStyles = makeStyles(theme => ({
    root: {
        'padding': '9px 32px',
        'boxShadow': 'none',
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
}));


const PrimaryButton = ({children, ...props}) => {
    const styles = useStyles();
    return (
        <Button
            variant="contained"
            color="primary"
            className={styles.root}
            {...props}
        >
            {children}
        </Button>

    );
};

export default PrimaryButton;
