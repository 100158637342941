import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Box, Typography, makeStyles} from '@material-ui/core';
import AtheneAvatar from '../../ui-components/AtheneAvatar/AtheneAvatar';
import resolveRoute from '../../application/lib/resolveRoute';
import routePaths from '../../routePaths';

const useStyles = makeStyles(theme => ({
    leaderBoardItemWrapper: {
        'display': 'flex',
        'alignItems': 'center',
        'flexGrow': 1,
        'height': '72px',
        'textDecoration': 'none',
        '&:hover': {
            backgroundColor: theme.palette.blue[50],
        },
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
}));

const LeaderBoardItem = ({user, isMostAnswerItem, index}) => {
    const styles = useStyles();
    const number = index > 9 ? index : `0${index}`;
    return (
        <Box
            component={Link}
            to={resolveRoute(routePaths.USER_ANSWERED, {userId: user.id})}
            className={styles.leaderBoardItemWrapper}
            my={0.5}
        >
            <Typography className={styles.marginLeft} component="p" variant="h3" color="textPrimary">{number}</Typography>
            <Box className={styles.marginLeft}>
                <AtheneAvatar initials={user.initials} isLeader={user.isLeader} />
            </Box>
            <Typography className={styles.marginLeft} component="h3" variant="h3" color="textPrimary">{user.name}</Typography>
            <Typography
                className={styles.marginLeft}
                component="p"
                variant="caption"
                color="textSecondary"
            >
                {`${user.entriesCount} ${isMostAnswerItem
                    ? `Answer${user.entriesCount > 1 ? 's' : ''}` : `Question${user.entriesCount > 1 ? 's' : ''}`}`}
            </Typography>
        </Box>
    );
};

LeaderBoardItem.propTypes = {
    user: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isMostAnswerItem: PropTypes.bool,
};

LeaderBoardItem.defaultProps = {
    isMostAnswerItem: false,
};

export default LeaderBoardItem;
