import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Grid, makeStyles} from '@material-ui/core';
import AtheneRow from '../../ui-components/AtheneRow/AtheneRow';
import AtheneAvatar from '../../ui-components/AtheneAvatar/AtheneAvatar';
import TalkBubble from '../../ui-components/TalkBubble/TalkBubble';
import Tag from '../../ui-components/Tag/Tag';
import routePaths from '../../routePaths';

const useStyles = makeStyles({
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    userWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    flexGrow: {
        flexGrow: 1,
    },
    rightBubbleWrapper: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
    leftBubbleWrapper: {
        display: 'flex',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
    },
});

const ProfileHeader = ({user}) => {
    const styles = useStyles();
    return (
        <AtheneRow>
            <Grid component="div" item xs={8} className={styles.flexGrow}>
                <Box component="div" className={styles.flexStart} pt={4}>
                    <AtheneAvatar variant="big" initials={user.initials} />
                    <Box component="div" ml={2} className={styles.flexGrow}>
                        <Box mb={2} className={styles.userWrapper}>
                            <Box className={styles.flexGrow}>
                                <Typography component="p" variant="h1" color="textPrimary" style={{whiteSpace: 'nowrap'}}>{user.name}</Typography>
                                <Typography component="p" variant="caption" color="textSecondary">{user.email}</Typography>
                            </Box>
                            <Box className={styles.flexEnd}>
                                {user.tags && user.tags.length && user.tags.map(tag => {
                                    return (
                                        <Box key={tag.name} ml={2} mb={1}>
                                            <Tag variant="big" name={tag.name} />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>

                        <Box component="div" className={styles.flexStart}>
                            <Box className={styles.leftBubbleWrapper} mr={1}>
                                <TalkBubble
                                    text={`Questions from ${user.name}`}
                                    variant="right"
                                    to={routePaths.MY_QUESTIONS}
                                />
                            </Box>
                            <Box component="div" className={styles.rightBubbleWrapper} mt={7.5}>
                                <TalkBubble text={`Answers from ${user.name}`} variant="left" to={routePaths.MY_ANSWERS} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </AtheneRow>
    );
};

ProfileHeader.propTypes = {
    user: PropTypes.object,
};

ProfileHeader.defaultProps = {
    user: {},
};

export default ProfileHeader;
