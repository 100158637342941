import {fork, take, put} from 'redux-saga/effects';
import getTags from './sagas/getTags';
import * as tagsActionTypes from './tagsActionTypes';


export const loadTags = function* (params) {
    yield put({
        type: tagsActionTypes.STORE_TAGS,
        payload: {tagsData: null},
    });

    yield fork(getTags);

    const actionResult = yield take([
        tagsActionTypes.GET_TAGS_REQUEST_SUCCEEDED,
        tagsActionTypes.GET_TAGS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: tagsActionTypes.STORE_TAGS,
            payload: {tagsData: actionResult.payload.response},
        });
    }
};
