import React from 'react';
import {Container, Box, makeStyles} from '@material-ui/core';
import Header from './view-elements/Header';
import Footer from './view-elements/Footer';

const useStyles = makeStyles({
    root: {
        padding: 0,
    },
    mainContentWrapper: {
        minHeight: 'calc(100vh - 330px)',
    },
});

const AppLayoutView = props => {
    const styles = useStyles();
    return (
        <Container component="div" maxWidth={false} className={styles.root}>
            <Header />
            <Box py={7} className={styles.mainContentWrapper}>
                {props.children}
            </Box>
            <Footer />
        </Container>
    );
};

export default AppLayoutView;
