import {fork, take, put} from 'redux-saga/effects';
import getQuestions from './getQuestions';
import * as questionsActionTypes from '../questionsActionTypes';

const getSearchedByTitleQuestionsFlow = function* () {
    while (true) {
        const {payload} = yield take(questionsActionTypes.START_SEARCHING_BY_TITLE_FLOW);
        const {title} = payload;
        const questionsType = 'search-autocomplete';

        yield fork(getQuestions, {questionsType, search: {title}});

        const actionResult = yield take([
            questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
            questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            yield put({
                type: questionsActionTypes.STORE_SEARCHED_BY_TITLE_AUTOCOMPLETE_QUESTIONS,
                payload: {questionsData: actionResult.payload.response},
            });
        }
    }
};

export default getSearchedByTitleQuestionsFlow;
