import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Box, Typography, Grid} from '@material-ui/core';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import AskQuestion from '../questions/view-elements/AskQuestion';
import Tag from '../ui-components/Tag/Tag';
import AppLayoutView from '../application/AppLayoutView';

const TagsView = () => {
    const tags = useSelector(state => (state.tags.tagsList ? state.tags.tagsList.toJS() : null));
    return (
        <AppLayoutView>
            <Box component="main" py={7}>
                <AtheneRow>
                    <AskQuestion />
                </AtheneRow>
                {tags ? (
                    <Box py={7}>
                        {Object.keys(tags).map(letter => {
                            return (
                                <Fragment key={letter}>
                                    <AtheneRow>
                                        <Grid component="div" xs={8} item>
                                            <Typography component="h2" variant="h2" color="textPrimary">{letter.toUpperCase()}</Typography>
                                        </Grid>
                                    </AtheneRow>
                                    <AtheneRow>
                                        <Grid component="div" item xs={8} style={{display: 'flex', flexWrap: 'wrap'}}>
                                            {tags[letter] && tags[letter].map(tag => {
                                                return (
                                                    <Box mr={2} mb={2} key={tag}>
                                                        <Tag variant="big" name={tag} />
                                                    </Box>
                                                );
                                            })}
                                        </Grid>
                                    </AtheneRow>
                                </Fragment>
                            );
                        })}
                    </Box>
                ) : (
                    <Typography component="h2" variant="h2" color="textPrimary" align="center">Loading...</Typography>
                )}
            </Box>
        </AppLayoutView>
    );
};

export default TagsView;
