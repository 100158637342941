import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Grid} from '@material-ui/core';

const useStyles = makeStyles({
    flexGrow: {
        flexGrow: 1,
    },
});

const AtheneRow = props => {
    const styles = useStyles();
    return (
        <Grid component="div" container spacing={2} alignItems={props.alignment} style={{margin: 0, width: '100%'}}>
            <Grid component="div" item xs={2} className={styles.flexGrow} />
            {props.children}
            <Grid component="div" item xs={2} className={styles.flexGrow} />
        </Grid>
    );
};

AtheneRow.propTypes = {
    alignment: PropTypes.string,
};
AtheneRow.defaultProps = {
    alignment: 'center',
};

export default AtheneRow;
