import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Box, Paper, Popover, Typography, Button} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {makeStyles} from '@material-ui/core/styles';
import {CustomButtonLink} from '../Links';
import AtheneAvatar from '../AtheneAvatar/AtheneAvatar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        position: 'relative',
    },
    paper: {
        width: '225px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    dropDown: {
        top: '50px',
        position: 'absolute',
    },
    menuLinksWrapper: {
        listStyleType: 'none',
    },
    logoutLink: {
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        width: '100%',
        padding: '8px 0 8px 16px',
    },
}));

const MenuDropDown = ({title, subLinks, isTextLink, isWithIcon, position, onLogoutClick, initials}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.root}>
            {isTextLink ? (
                <Typography
                    component="p"
                    variant="body1"
                    color={open ? 'textPrimary' : 'textSecondary'}
                    aria-describedby={id}
                    onClick={handleClick}
                    className={classes.title}
                >
                    {title}
                    {isWithIcon && (<ArrowDropDownIcon component="svg" style={{color: 'inherit'}} />)}
                </Typography>
            ) : (
                <AtheneAvatar isLink={true} initials={initials} onClick={handleClick} />
            )}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: position,
                }}
                transformOrigin={{
                    vertical: -20,
                    horizontal: position,
                }}
            >
                <Paper className={classes.paper}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box component="ul" py={2}>
                            {subLinks && subLinks.map((link, idx) => {
                                return (
                                    <Box
                                        key={`${link.title}-${idx}`}
                                        component="li"
                                        className={classes.menuLinksWrapper}
                                    >
                                        <CustomButtonLink to={link.to} title={link.title} />
                                    </Box>
                                );
                            })}
                            {!isTextLink && (
                                <Box
                                    key="Logout-2"
                                    component="li"
                                    className={classes.menuLinksWrapper}
                                >
                                    <Button
                                        color="primary"
                                        component="div"
                                        className={classes.logoutLink}
                                        onClick={onLogoutClick}
                                    >
                                        <Typography component="p" variant="body1">
                                            Logout
                                        </Typography>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </div>
    );
};

MenuDropDown.propTypes = {
    title: PropTypes.string,
    subLinks: PropTypes.array.isRequired,
    isTextLink: PropTypes.bool,
    isWithIcon: PropTypes.bool,
    position: PropTypes.string,
    onLogoutClick: PropTypes.func,
    initials: PropTypes.string,
};

MenuDropDown.defaultProps = {
    title: '',
    isTextLink: false,
    isWithIcon: false,
    position: 'left',
    onLogoutClick: null,
    initials: '',
};

export default MenuDropDown;
