import Immutable from 'immutable';
import {User} from './records/User';
import * as usersActionTypes from './usersActionTypes';

const initialState = {
    answerLeaders: Immutable.OrderedMap(),
    questionLeaders: Immutable.OrderedMap(),
    answerLeadersIds: null,
    questionLeadersIds: null,
    currentUser: null,
};

const setUsersData = usersDTO => {
    let isLeader = true;
    let users = Immutable.OrderedMap();
    let usersIds = [];
    usersDTO.forEach(userDTO => {
        const transformedUserDTO = {
            ...userDTO,
            ...userDTO.user,
            isLeader,
        };
        const user = new User({}).fromDTO(transformedUserDTO);
        if (user.id) {
            users = users.set(user.id, user);
            usersIds = [...usersIds, user.id];
        }
        isLeader = false;
    });
    return {users, usersIds};
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case usersActionTypes.RESET_LEADERS_STATUS: {
            const {leadersIds} = action.payload;
            state = {
                ...state,
                [leadersIds]: null,
            };
            break;
        }
        case usersActionTypes.STORE_ANSWER_LEADERS: {
            const {usersData: usersDTO} = action.payload;
            const {users, usersIds} = setUsersData(usersDTO);
            state = {
                ...state,
                answerLeaders: state.answerLeaders.merge(users),
                answerLeadersIds: usersIds,
            };
            break;
        }
        case usersActionTypes.STORE_QUESTION_LEADERS: {
            const {usersData: usersDTO} = action.payload;
            const {users, usersIds} = setUsersData(usersDTO);
            state = {
                ...state,
                questionLeaders: state.questionLeaders.merge(users),
                questionLeadersIds: usersIds,
            };
            break;
        }
        case usersActionTypes.STORE_CURRENT_USER: {
            const {userData: userDTO} = action.payload;
            const currentUser = userDTO ? new User({}).fromDTO(userDTO) : null;
            state = {
                ...state,
                currentUser,
            };
            break;
        }
    }
    return state;
};

export default usersReducer;
