import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import authReducer from './auth/authReducer';
import questionsReducer from './questions/questionReducer';
import usersReducer from './users/usersReducer';
import tagsReducer from './tags/tagsReducer';

const createRootReducer = history => {
    return combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        questions: questionsReducer,
        users: usersReducer,
        tags: tagsReducer,
    });
};

export default createRootReducer;
