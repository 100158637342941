import routePaths from './routePaths';
import * as authLoaderSagas from './auth/authLoaderSagas';
import * as questionsLoaderSagas from './questions/questionsLoaderSagas';
import * as usersLoaderSagas from './users/usersLoaderSagas';
import * as tagsLoaderSagas from './tags/tagsLoaderSaga';
import App from './application/App';
import LatestQuestionsView from './questions/LatestQuestionsView';
import UnansweredQuestionsView from './questions/UnansweredQuestionsView';
import MostViewedQuestionsView from './questions/MostViewedQuestionsView';
import MyOpenQuestionsView from './questions/MyOpenQuestionsView';
import MyAnsweredQuestionsView from './questions/MyAnsweredQuestionsView';
import MyLastViewedQuestionsView from './questions/MyLastViewedQuestionsView';
import ProfileAnsweredQuestionsView from './questions/ProfileAnsweredQuestionsView';
import ProfileOpenQuestionsView from './questions/ProfileOpenQuestionsView';
import SearchedQuestionView from './questions/SearchedQuestionsView';
import BookmarksView from './questions/BookmarksView';
import TagsView from './tags/TagsView';
import LeaderBoardView from './users/LeaderBoardView';
import SignInView from './auth/SignInView';
import PageNotFoundView from './auth/PageNotFoundView';
import {userState} from './auth/constants/userState';

export default [{
    component: App,
    locationChangeSideEffects: [
        [authLoaderSagas.loadUserSession],
    ],
    routes: [
        {
            path: routePaths.LATEST_QUESTIONS,
            exact: true,
            component: LatestQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadLatestQuestions],
            ],
        },
        {
            path: routePaths.MOST_VIEWED_QUESTIONS,
            exact: true,
            component: MostViewedQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadMostViewedQuestions],
            ],
        },
        {
            path: routePaths.UNANSWERED_QUESTIONS,
            exact: true,
            component: UnansweredQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUnansweredQuestions],
            ],
        },
        {
            path: routePaths.MY_OPEN_QUESTIONS,
            exact: true,
            component: MyOpenQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserOpenQuestions],
            ],
        },
        {
            path: routePaths.MY_ANSWERED_QUESTIONS,
            exact: true,
            component: MyAnsweredQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserAnsweredQuestions],
            ],
        },
        {
            path: routePaths.MY_LAST_VIEWED_QUESTIONS,
            exact: true,
            component: MyLastViewedQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadMyLastViewedQuestions],
            ],
        },
        {
            path: routePaths.LEADER_BOARD,
            exact: true,
            component: LeaderBoardView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [usersLoaderSagas.loadLeaders],
            ],
        },
        {
            path: routePaths.PROFILE_ANSWERED,
            exact: true,
            component: ProfileAnsweredQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserAnsweredQuestions],
            ],
        },
        {
            path: routePaths.USER_ANSWERED,
            exact: true,
            component: ProfileAnsweredQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserAnsweredQuestions],
            ],
        },
        {
            path: routePaths.PROFILE_OPEN,
            exact: true,
            component: ProfileOpenQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserOpenQuestions],
            ],
        },
        {
            path: routePaths.USER_OPEN,
            exact: true,
            component: ProfileOpenQuestionsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadUserOpenQuestions],
            ],
        },
        {
            path: routePaths.BOOKMARKS,
            exact: true,
            component: BookmarksView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadBookmarkedQuestions],
            ],
        },
        {
            path: routePaths.TAGS,
            exact: true,
            component: TagsView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [tagsLoaderSagas.loadTags],
            ],
        },
        {
            path: routePaths.SEARCHED_QUESTIONS,
            exact: true,
            component: SearchedQuestionView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.SIGN_IN,
                }],
                [questionsLoaderSagas.loadSearchedByTitleQuestions],
            ],
        },
        {
            path: routePaths.OAUTH,
            exact: true,
            component: SignInView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_OUT,
                    redirectTo: routePaths.LATEST_QUESTIONS,
                }],
                [authLoaderSagas.loadOAuth2AccessToken],
            ],
        },
        {
            path: routePaths.SIGN_IN,
            exact: true,
            component: SignInView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_OUT,
                    redirectTo: routePaths.LATEST_QUESTIONS,
                }],
            ],
        },
        {
            path: '*',
            component: PageNotFoundView,
        },
    ],
}];
