import qs from 'qs';
import config from '../../config';
import handleResponse from './handleResponse';
import {getAccessToken} from '../../application/lib/storeService';

export const getQuestions = params => {
    const accessToken = getAccessToken();
    const {search} = params;
    const {page, title} = qs.parse(search, {ignoreQueryPrefix: true});

    const query = qs.stringify({
        ...(page && {page: +page}),
        ...(title && {title}),
    });

    return fetch(
        `${config.API_URL}${config.ENDPOINTS.QUESTIONS}/${params.questionsType}${params.userId ? `/${params.userId}` : ''}?${query}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/ld+json',
                'Authorization': `Bearer ${accessToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
