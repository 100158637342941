import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Typography} from '@material-ui/core';
import {Link, matchPath} from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    button: {
        flexGrow: 1,
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    borderActive: {
        flexGrow: 1,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItems: 'flex-end',
    },
    border: {
        flexGrow: 1,
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        textTransform: 'capitalize',
    },

}));

const AtheneTab = ({to, title}) => {
    const styles = useStyles();
    const location = useLocation();
    const isActiveTab = matchPath(location.pathname, {
        path: to,
        exact: true,
    });
    return (
        <Box className={isActiveTab ? styles.borderActive : styles.border}>
            <Button component={Link} to={to} className={styles.button}>
                <Typography
                    component="p"
                    variant="h4"
                    color={isActiveTab ? 'textPrimary' : 'textSecondary'}
                    className={styles.title}
                >
                    {title}
                </Typography>
            </Button>
        </Box>
    );
};

AtheneTab.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default AtheneTab;
