import {Record} from 'immutable';
import {makeInitials} from '../../application/lib/index';

/**
 * User definition
 *
 * @typedef {Immutable.Record} User
 * @property {?string} id
 * @property {?string} name
 * @property {?string} initials
 * @property {?string} email
 * @property {?number} entriesCount
 * @property {?number} questionsCount
 * @property {?array} tags
 */

/**
 * User record
 *
 * @type {User}
 */

export class User extends Record({
    id: null,
    name: null,
    initials: null,
    email: null,
    entriesCount: null,
    isLeader: null,
    tags: null,
}) {
    fromDTO(userDTO) {
        return new User({
            ...this.toObject(),
            id: userDTO.id,
            name: userDTO.name,
            email: userDTO.email,
            entriesCount: userDTO.entriesCount,
            initials: makeInitials(userDTO.name),
            isLeader: userDTO.isLeader,
            tags: userDTO.tags,
        });
    }
}
