import {Record} from 'immutable';
import {makeInitials} from '../../application/lib/index';

/**
 * UserAccount definition
 *
 * @typedef {Immutable.Record} UserAccount
 * @property {?string} id
 * @property {?string} email
 * @property {?string} name
 * @property {?string} picture
 * @property {?string} initials
 * @property {?array} tags
 */

/**
 * UserAccount record
 *
 * @type {UserAccount}
 */
class UserAccount extends Record({
    id: null,
    email: null,
    name: null,
    picture: null,
    initials: null,
    tags: null,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {UserAccount}
     */
    fromDTO(dataTransferObject) {
        return new UserAccount({
            ...this.toObject(),
            ...dataTransferObject,
            initials: makeInitials(dataTransferObject.name),
        });
    }
}

export default UserAccount;
