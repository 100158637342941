import {getDataByIds} from '../application/lib/selectors';

export const getQuestions = state => state.questions.questions.toJS();

export const getLatestQuestionsIds = state => state.questions.latestQuestionsIds;
export const getUnansweredQuestionsIds = state => state.questions.unansweredQuestionsIds;
export const getMostViewedQuestionsIds = state => state.questions.mostViewedQuestionsIds;
export const getUserOpenQuestionsIds = state => state.questions.userOpenQuestionsIds;
export const getUserAnsweredQuestionsIds = state => state.questions.userAnsweredQuestionsIds;
export const getMyLatestViewedQuestionsIds = state => state.questions.myLastViewedQuestionsIds;
export const getBookmarkedQuestionsIds = state => state.questions.bookmarkedQuestionsIds;
export const getSearchedByTitleQuestionsIds = state => state.questions.searchedByTitleQuestionsIds;
export const getSearchedByTitleAutocompleteQuestionsIds = state => {
    return state.questions.searchedByTitleAutocompleteQuestionsIds;
};

export const getLatestQuestions = getDataByIds(getQuestions, getLatestQuestionsIds);
export const getUnansweredQuestions = getDataByIds(getQuestions, getUnansweredQuestionsIds);
export const getMostViewedQuestions = getDataByIds(getQuestions, getMostViewedQuestionsIds);
export const getUserOpenQuestions = getDataByIds(getQuestions, getUserOpenQuestionsIds);
export const getUserAnsweredQuestions = getDataByIds(getQuestions, getUserAnsweredQuestionsIds);
export const getMyLatestViewedQuestions = getDataByIds(getQuestions, getMyLatestViewedQuestionsIds);
export const getBookmarkedQuestions = getDataByIds(getQuestions, getBookmarkedQuestionsIds);
export const getSearchedByTitleQuestions = getDataByIds(getQuestions, getSearchedByTitleQuestionsIds);
export const getSearchedByTitleAutocompleteQuestions = getDataByIds(
    getQuestions,
    getSearchedByTitleAutocompleteQuestionsIds,
);
