import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import AskQuestion from './view-elements/AskQuestion';
import QuestionsTable from './view-elements/QuestionsTable';
import AtheneTabs from '../ui-components/AtheneTabs/AtheneTabs';
import AtheneTab from '../ui-components/AtheneTabs/AtheneTab';


const QuestionsPanel = ({questions, totalPages, page, handlePagination, tabs}) => {
    return (
        <Box component="main">
            <AtheneRow>
                <AskQuestion />
            </AtheneRow>
            <AtheneRow>
                <AtheneTabs>
                    {tabs && tabs.map(tab => {
                        return (
                            <AtheneTab key={tab.title} title={tab.title} to={tab.to} />
                        );
                    })}
                </AtheneTabs>
            </AtheneRow>
            <QuestionsTable
                questions={questions}
                totalPages={totalPages}
                page={page}
                handlePagination={handlePagination}
            />
        </Box>
    );
};

QuestionsPanel.propTypes = {
    questions: PropTypes.array,
    totalPages: PropTypes.number,
    page: PropTypes.number,
    handlePagination: PropTypes.func.isRequired,
    tabs: PropTypes.array.isRequired,
};

QuestionsPanel.defaultProps = {
    questions: null,
    totalPages: 1,
    page: 1,
};


export default QuestionsPanel;
