import * as tagsActionTypes from './tagsActionTypes';
import Tags from './record/Tags';

const initialState = {
    tagsList: null,
};

/**
 * Tags reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case tagsActionTypes.STORE_TAGS: {
            const tagsDTO = action.payload.tagsData;

            state = {
                ...state,
                tagsList: tagsDTO ? new Tags({}).fromDTO(tagsDTO) : null,
            };
            break;
        }
        default:
        // no-op
    }
    return state;
};

export default tagsReducer;
