import routePaths from '../../routePaths';
import resolveRoute from '../../application/lib/resolveRoute';

export const questionTabs = [
    {title: 'Latest Questions', to: routePaths.LATEST_QUESTIONS},
    {title: 'Unanswered', to: routePaths.UNANSWERED_QUESTIONS},
    {title: 'Most Viewed', to: routePaths.MOST_VIEWED_QUESTIONS},

];

export const myQuestionsTabs = [
    {title: 'My open questions', to: routePaths.MY_OPEN_QUESTIONS},
    {title: 'My answered questions', to: routePaths.MY_ANSWERED_QUESTIONS},
    {title: 'My last viewed questions', to: routePaths.MY_LAST_VIEWED_QUESTIONS},
];

export const myProfileQuestionsTabs = [
    {title: 'Answered questions', to: routePaths.PROFILE_ANSWERED},
    {title: 'Open questions', to: routePaths.PROFILE_OPEN},
];

export const createUserQuestionsTabs = userId => {
    return [
        {title: 'Answered questions', to: resolveRoute(routePaths.USER_ANSWERED, {userId}).pathname},
        {title: 'Open questions', to: resolveRoute(routePaths.USER_OPEN, {userId}).pathname},
    ];
};
