import {Record} from 'immutable';

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const defaultValues = alphabet.reduce((acc, curr) => {
    return {
        ...acc,
        [curr]: null,
    };
}, {});

/**
 * Tags definition
 *
 * @typedef {Immutable.Record} TagsGroup
 */

/**
 * Tags record
 *
 * @type {Tags}
 */
class Tags extends Record({
    ...defaultValues,
}) {
    /**
     * Set values from data transfer object
     *
     * @returns {Tags}
     */
    fromDTO(dataTransferObject) {
        return new Tags({
            ...this.toObject(),
            ...dataTransferObject,
        });
    }
}

export default Tags;
