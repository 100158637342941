import React from 'react';
import {useSelector} from 'react-redux';
import qs from 'qs';
import {useHistory, useLocation} from 'react-router';
import {Grid, Box} from '@material-ui/core';
import AppLayoutView from '../application/AppLayoutView';
import QuestionsTable from './view-elements/QuestionsTable';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import PageTitleSection from './view-elements/PageTitleSection';
import * as questionsSelectors from './questionsSelectors';
import {calculateTotalPagesNumber} from './lib/questionPagination';


const BookmarksView = () => {
    const history = useHistory();
    const location = useLocation();
    const queryObject = qs.parse(location.search, {ignoreQueryPrefix: true, comma: true});
    const page = queryObject.page ? +queryObject.page : 1;
    const questions = useSelector(state => questionsSelectors.getBookmarkedQuestions(state));
    const totalRecords = useSelector(state => state.questions.totalCount);
    const totalPages = calculateTotalPagesNumber(totalRecords, 10);
    const handlePagination = (event, value) => {
        const query = qs.stringify({page: value});
        history.push({
            pathname: location.pathname,
            search: query,
        });
    };
    return (
        <AppLayoutView>
            <AtheneRow>
                <Grid component="div" item xs={8}>
                    <Box mb={2}>
                        <PageTitleSection title="Bookmarks" />
                    </Box>
                </Grid>
            </AtheneRow>
            <QuestionsTable
                questions={questions}
                totalPages={totalPages}
                page={page}
                handlePagination={handlePagination}
            />
        </AppLayoutView>
    );
};

export default BookmarksView;

