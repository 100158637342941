export const calculateTotalPagesNumber = (total, pageSize) => {
    const totalPages = total / pageSize;
    if (totalPages === 0) {
        return 1;
    }
    if (total % pageSize) {
        return Math.ceil(totalPages);
    }
    return totalPages;
};
