import React, {useState} from 'react';
import {makeStyles, Box, Grid, Typography, FormControl} from '@material-ui/core';
import AtheneAvatar from '../../ui-components/AtheneAvatar/AtheneAvatar';
import QuestionInput from '../../ui-components/Input/QuestionInput';

const useStyles = makeStyles(theme => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        marginLeft: theme.spacing(1),
    },
}));

const AskQuestion = () => {
    const styles = useStyles();
    const [question, setQuestion] = useState('');
    return (
        <Grid component="div" item xs={8}>
            <Box component="div" className={styles.centerFlex}>
                <AtheneAvatar initials="SV" />
                <Typography
                    component="p"
                    color="primary"
                    variant="body1"
                    className={styles.name}
                >
                    Slobodan Vukovojac
                </Typography>
            </Box>
            <FormControl component="div" fullWidth>
                <QuestionInput
                    id="standard-adornment-amount"
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    placeholder="Do you have a question?"
                />
            </FormControl>
        </Grid>
    );
};

export default AskQuestion;
