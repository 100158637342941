import React from 'react';
import {useSelector} from 'react-redux';
import qs from 'qs';
import {useHistory, useLocation} from 'react-router';
import AppLayoutView from '../application/AppLayoutView';
import QuestionsPanel from './QuestionsPanel';
import * as questionsSelectors from './questionsSelectors';
import {calculateTotalPagesNumber} from './lib/questionPagination';
import {myQuestionsTabs} from './lib/tabInformation';


const MyLastViewedQuestionsView = () => {
    const history = useHistory();
    const location = useLocation();
    const queryObject = qs.parse(location.search, {ignoreQueryPrefix: true, comma: true});
    const page = queryObject.page ? +queryObject.page : 1;
    const questions = useSelector(state => questionsSelectors.getMyLatestViewedQuestions(state));
    const totalRecords = useSelector(state => state.questions.totalCount);
    const totalPages = calculateTotalPagesNumber(totalRecords, 10);
    const handlePagination = (event, value) => {
        const query = qs.stringify({page: value});
        history.push({
            pathname: location.pathname,
            search: query,
        });
    };
    return (
        <AppLayoutView>
            <QuestionsPanel
                handlePagination={handlePagination}
                questions={questions}
                page={page}
                totalPages={totalPages}
                tabs={myQuestionsTabs}
            />
        </AppLayoutView>
    );
};

export default MyLastViewedQuestionsView;
