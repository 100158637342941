import React from 'react';
import {Button, makeStyles} from '@material-ui/core/index';


const useStyles = makeStyles(theme => ({
    root: {
        'padding': '9px 32px',
        'boxShadow': 'none',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        '&$disabled': {
            'backgroundColor': theme.palette.blue[700],
            'color': theme.palette.text.primary,
        },
    },
    disabled: {},
}));


const SecondaryButton = ({children, ...props}) => {
    const styles = useStyles();
    return (
        <Button
            variant="contained"
            color="secondary"
            className={styles.root}
            classes={{disabled: styles.disabled}}
            {...props}
        >
            {children}
        </Button>

    );
};

export default SecondaryButton;
