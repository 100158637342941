import {call, put} from 'redux-saga/effects';
import * as questionsActionTypes from '../questionsActionTypes';
import * as questionApi from '../questions-services/questionsApi';

const getQuestions = function* (params) {
    try {
        yield put({
            type: questionsActionTypes.GET_QUESTIONS_REQUEST_SENT,
            payload: params,
        });

        const response = yield call(questionApi.getQuestions, params);
        yield put({
            type: questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
            payload: {response},
        });
    } catch (error) {
        yield put({type: questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED, error: true, payload: error});
    }
};

export default getQuestions;
