import {all, fork} from 'redux-saga/effects';
import getSearchedByTitleQuestionsFlow from './sagas/getSearchedByTitleQuestionsFlow';

/**
 * Auth watcher saga
 */
const questionWatcher = function* () {
    yield all([
        fork(getSearchedByTitleQuestionsFlow),
    ]);
};

export default questionWatcher;
