import React from 'react';
import qs from 'qs';
import {useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import {Box, Typography, Grid} from '@material-ui/core';
import * as questionsSelectors from './questionsSelectors';
import AppLayoutView from '../application/AppLayoutView';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import PageTitleSection from './view-elements/PageTitleSection';
import {calculateTotalPagesNumber} from './lib/questionPagination';
import QuestionsTable from './view-elements/QuestionsTable';


const SearchedQuestionView = () => {
    const location = useLocation();
    const history = useHistory();
    const queryObject = qs.parse(location.search, {ignoreQueryPrefix: true, comma: true});
    const {title} = queryObject;
    const page = queryObject.page ? +queryObject.page : 1;
    const questions = useSelector(state => questionsSelectors.getSearchedByTitleQuestions(state));
    const totalRecords = useSelector(state => state.questions.totalCount);
    const totalPages = calculateTotalPagesNumber(totalRecords, 10);
    const handlePagination = (event, value) => {
        const query = qs.stringify({page: value});
        history.push({
            pathname: location.pathname,
            search: query,
        });
    };
    return (
        <AppLayoutView>
            <AtheneRow>
                <Grid component="div" item xs={8}>
                    <PageTitleSection title={`Search results for “${title}”:`} />
                </Grid>
            </AtheneRow>
            {questions ? (
                <QuestionsTable
                    questions={questions}
                    totalPages={totalPages}
                    page={page}
                    handlePagination={handlePagination}
                    noQuestionsNote={`Sorry, we can not found "${title}", please try something else!`}
                />
            ) : (
                <Box py={30}>
                    <Typography component="h2" variant="h1" color="textPrimary" align="center">Loading...</Typography>
                </Box>
            )}
        </AppLayoutView>
    );
};

export default SearchedQuestionView;
