import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Grid} from '@material-ui/core';
import AtheneRow from '../../ui-components/AtheneRow/AtheneRow';
import AthenePagination from '../../application/view-elements/AthenePagination';
import QuestionRow from './QuestionRow';

const QuestionsTable = ({questions, totalPages, page, handlePagination, noQuestionsNote}) => {
    return (
        <Fragment>
            {questions ? (
                <Fragment>
                    {questions.length > 0 ? questions.map(question => {
                        return (
                            <AtheneRow key={question.id}>
                                <QuestionRow question={question} />
                            </AtheneRow>
                        );
                    }) : (
                        <AtheneRow>
                            <Grid component="div" item xs={8}>
                                <Box component="div" mt={8}>
                                    <Typography component="h1" color="primary" variant="h1" align="center">{noQuestionsNote}</Typography>
                                </Box>
                            </Grid>
                        </AtheneRow>

                    )}
                    {questions.length > 0 && (
                        <AthenePagination
                            count={totalPages}
                            page={page}
                            onChange={handlePagination}
                        />
                    )}
                </Fragment>
            ) : (
                <Box component="div" my={20}>
                    <Typography component="h1" color="primary" variant="h1" align="center">Loading...</Typography>
                </Box>
            )}
        </Fragment>
    );
};

QuestionsTable.propTypes = {
    questions: PropTypes.array,
    totalPages: PropTypes.number,
    page: PropTypes.number,
    handlePagination: PropTypes.func.isRequired,
    noQuestionsNote: PropTypes.string,
};

QuestionsTable.defaultProps = {
    questions: null,
    totalPages: 1,
    page: 1,
    noQuestionsNote: 'There are no questions',
};


export default QuestionsTable;
