import {fork, take, put} from 'redux-saga/effects';
import getAnswersLeaders from './getAnswerLeaders';
import * as usersActionTypes from '../usersActionTypes';

const getAnswerLeadersFlow = function* () {
    yield put({
        type: usersActionTypes.RESET_LEADERS_STATUS,
        payload: {leadersIds: 'answerLeadersIds'},
    });

    yield fork(getAnswersLeaders, {usersTypes: 'answer-leaders'});

    const actionResult = yield take([
        usersActionTypes.GET_ANSWER_LEADERS_REQUEST_SUCCEEDED,
        usersActionTypes.GET_ANSWER_LEADERS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: usersActionTypes.STORE_ANSWER_LEADERS,
            payload: {usersData: actionResult.payload.response},
        });
    }
};

export default getAnswerLeadersFlow;
