import React from 'react';
import {Box, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const ComputerRockIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M0 29.543V29.5262C0 27.9672 1.17501 26.6747 2.81277 26.6747C3.82061 26.6747 4.42387 27.0303 4.98109 27.5492L4.55227 28.0056C4.08469 27.5636 3.55896 27.2489 2.8055 27.2489C1.57476 27.2489 0.651709 28.241 0.651709 29.5094V29.5262C0.651709 30.8018 1.58203 31.8036 2.8055 31.8036C3.56865 31.8036 4.06773 31.5129 4.60073 31.0084L5.01501 31.4096C4.43598 31.9933 3.80123 32.3777 2.79096 32.3777C1.18471 32.3801 0 31.1261 0 29.543Z" fill="#165699" />
            <path d="M5.70312 29.543V29.5262C5.70312 28.0056 6.85391 26.6747 8.54739 26.6747C10.2384 26.6747 11.3747 27.9888 11.3747 29.5094V29.5262C11.3747 31.0469 10.2239 32.3777 8.53043 32.3777C6.83695 32.3777 5.70312 31.0637 5.70312 29.543ZM10.723 29.543V29.5262C10.723 28.2746 9.80235 27.2513 8.53043 27.2513C7.26093 27.2513 6.35483 28.2602 6.35483 29.5118V29.5286C6.35483 30.7802 7.27546 31.806 8.54739 31.806C9.81689 31.8036 10.723 30.797 10.723 29.543Z" fill="#165699" />
            <path d="M12.7021 26.7708H13.3369L15.3623 29.7808L17.3877 26.7708H18.0224V32.284H17.3949V27.8182L15.3696 30.773H15.3381L13.3127 27.8278V32.284H12.7021V26.7708Z" fill="#165699" />
            <path d="M19.5972 26.7708H21.6783C22.9332 26.7708 23.7594 27.4314 23.7594 28.5197V28.5365C23.7594 29.7256 22.7588 30.3406 21.5741 30.3406H20.2247V32.2864H19.5972V26.7708ZM21.5983 29.7712C22.519 29.7712 23.1222 29.2836 23.1222 28.5581V28.5413C23.1222 27.7533 22.5262 27.345 21.6298 27.345H20.2247V29.7712H21.5983Z" fill="#165699" />
            <path d="M24.8159 29.9778V26.7732H25.4434V29.9394C25.4434 31.1285 26.0782 31.7987 27.1417 31.7987C28.1665 31.7987 28.8182 31.1837 28.8182 29.9802V26.7756H29.4457V29.9346C29.4457 31.5417 28.5154 32.3753 27.1272 32.3753C25.7535 32.3705 24.8159 31.5369 24.8159 29.9778Z" fill="#165699" />
            <path d="M32.4011 27.3474H30.5356V26.7708H34.9038V27.3474H33.0359V32.284H32.4011V27.3474Z" fill="#165699" />
            <path d="M36.0791 26.7708H40.0984V27.3377H36.7066V29.2211H39.7422V29.788H36.7066V31.7171H40.1396V32.284H36.0791V26.7708Z" fill="#165699" />
            <path d="M41.4019 26.7708H43.7931C44.4763 26.7708 45.0238 26.975 45.3751 27.3209C45.644 27.59 45.8039 27.9743 45.8039 28.4092V28.426C45.8039 29.3388 45.1692 29.8745 44.2946 30.0331L46.0026 32.2864H45.2322L43.6211 30.1436H42.0318V32.2864H41.4043V26.7708H41.4019ZM43.7373 29.5839C44.5708 29.5839 45.1667 29.1587 45.1667 28.45V28.4332C45.1667 27.7557 44.6434 27.3474 43.7446 27.3474H42.0293V29.5839H43.7373Z" fill="#165699" />
            <path d="M11.6606 34.3931H14.0519C14.7351 34.3931 15.2826 34.5973 15.6339 34.9432C15.9028 35.2123 16.0627 35.5967 16.0627 36.0291V36.0459C16.0627 36.9587 15.428 37.4944 14.5534 37.653L16.2614 39.9063H15.4909L13.8798 37.7635H12.2905V39.9063H11.6631V34.3931H11.6606ZM13.9961 37.2038C14.8296 37.2038 15.4255 36.7786 15.4255 36.0699V36.0531C15.4255 35.3757 14.9022 34.9673 14.0034 34.9673H12.2881V37.2038H13.9961Z" fill="#165699" />
            <path d="M17.1577 37.1654V37.1485C17.1577 35.6279 18.3085 34.2971 20.002 34.2971C21.693 34.2971 22.8293 35.6135 22.8293 37.1317V37.1485C22.8293 38.6692 21.6785 40 19.985 40C18.2915 40 17.1577 38.686 17.1577 37.1654ZM22.1776 37.1654V37.1485C22.1776 35.897 21.2569 34.8736 19.985 34.8736C18.7155 34.8736 17.8094 35.8825 17.8094 37.1341V37.1509C17.8094 38.4025 18.7301 39.4283 20.002 39.4283C21.2715 39.4259 22.1776 38.4169 22.1776 37.1654Z" fill="#165699" />
            <path d="M23.9097 37.1654V37.1485C23.9097 35.5895 25.0847 34.2971 26.72 34.2971C27.7279 34.2971 28.3335 34.6526 28.8883 35.1715L28.4595 35.6279C27.9919 35.1859 27.4662 34.8712 26.7127 34.8712C25.482 34.8712 24.559 35.8633 24.559 37.1317V37.1485C24.559 38.4241 25.4893 39.4259 26.7127 39.4259C27.4759 39.4259 27.975 39.1352 28.508 38.6307L28.9222 39.0319C28.3432 39.6157 27.7085 40 26.6982 40C25.0944 40 23.9097 38.7484 23.9097 37.1654Z" fill="#165699" />
            <path d="M30.0195 34.3931H30.647V37.6842L33.8401 34.3931H34.6493L32.2654 36.8026L34.7535 39.9063H33.9661L31.8293 37.235L30.647 38.4241V39.9063H30.0195V34.3931Z" fill="#165699" />
            <path d="M19.8276 13.0226V11.3194L16.0239 4.7901L14.6333 7.18035L17.4679 9.99099V23.9529H18.5847V18.4926H19.4303V23.9529H20.5883V17.4572L18.7374 15.6219L19.3334 15.0285L21.4339 17.1089V23.9529H22.6719V18.4325H23.515V23.9529H24.673V17.8272L19.8276 13.0226Z" fill="#165699" />
            <path d="M30.8386 19.7682V14.4256L26.7297 10.3513V5.05435L28.8859 2.91634L27.1852 0L25.4917 2.90913H25.5159V14.243L27.5728 16.2801V23.9529H28.743V21.8461L30.8386 19.7682ZM26.8533 13.64L27.4492 13.0467L29.6636 15.2423V18.9442H28.8205V15.5883L26.8533 13.64Z" fill="#165699" />
            <path d="M24.6727 14.5889V4.31683L23.5147 6.3035V12.2179H22.6716V7.75206L20.6704 11.1897V12.6743L25.5183 17.4812V23.9529H26.7296V16.6284L24.6727 14.5889Z" fill="#165699" />
            <path d="M37.7893 21.3897V23.9553H41.135L35.73 14.673V19.3478L37.7893 21.3897Z" fill="#165699" />
            <path d="M12.4719 10.8918L11.2267 13.0299V18.5334L9.33211 20.412V22.771L8.14014 23.9529H10.0832L12.4719 21.5843V10.8918Z" fill="#165699" />
            <path d="M14.1972 7.92987L13.3153 9.44329V21.9326L11.2754 23.9529H13.2378H14.5339V12.72H15.3795V23.9529H16.6247V10.3393L14.1972 7.92987Z" fill="#165699" />
            <path d="M8.48914 20.0636L10.3837 18.1875V14.4784L4.86719 23.9553H6.94587L8.48914 22.4251V20.0636Z" fill="#165699" />
            <path d="M34.887 19.6937V13.2244L30.6618 5.9696L29.6758 6.94732V8.54723L33.6611 12.4989V23.9529H36.9463V21.7356L34.887 19.6937Z" fill="#165699" />
            <path d="M28.8301 8.89319V6.59903L30.2232 5.21773L29.322 3.66827L27.5728 5.4027V10.0054L31.6817 14.0773V20.1141L29.5884 22.1921V23.9529H30.8265V22.5861H31.672V23.9529H32.8155V12.8473L28.8301 8.89319Z" fill="#165699" />
        </SvgIcon>
    );
};

const useStyles = makeStyles(theme => ({
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        background: theme.palette.blue[100],
    },
    footerIcon: {
        width: '46px',
        height: '40px',
    },
}));

const Footer = () => {
    const styles = useStyles();
    return (
        <Box component="footer" className={styles.footer}>
            <ComputerRockIcon width="46" height="40" viewBox="0 0 46 40" className={styles.footerIcon} />
        </Box>
    );
};

export default Footer;
