import {call, put} from 'redux-saga/effects';
import * as tagsActionTypes from '../tagsActionTypes';
import * as tagsApi from '../tags-services/tagsApi';

const getTags = function* (params) {
    try {
        yield put({
            type: tagsActionTypes.GET_TAGS_REQUEST_SENT,
            payload: params,
        });

        const response = yield call(tagsApi.getTags, params);
        yield put({
            type: tagsActionTypes.GET_TAGS_REQUEST_SUCCEEDED,
            payload: {response},
        });
    } catch (error) {
        yield put({type: tagsActionTypes.GET_TAGS_REQUEST_FAILED, error: true, payload: error});
    }
};

export default getTags;
