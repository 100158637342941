import React, {Fragment} from 'react';
import qs from 'qs';
import {useHistory, useLocation} from 'react-router';
import {matchPath} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Box, Typography, Grid} from '@material-ui/core';
import AppLayoutView from '../application/AppLayoutView';
import ProfileHeader from './view-elements/ProfileHeader';
import * as questionsSelectors from './questionsSelectors';
import {getCurrentUser} from '../users/usersSelectors';
import {getUserAccount} from '../auth/authSelectors';
import {calculateTotalPagesNumber} from './lib/questionPagination';
import {myProfileQuestionsTabs, createUserQuestionsTabs} from './lib/tabInformation';
import ProfileQuestionsPanel from './ProfileQuestionsPanel';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import PageTitleSection from './view-elements/PageTitleSection';
import routePaths from '../routePaths';

const ProfileAnsweredQuestionsView = () => {
    const history = useHistory();
    const location = useLocation();
    const isMyProfileView = !!matchPath(location.pathname, {
        path: routePaths.PROFILE_ANSWERED,
    });
    const user = useSelector(state => (isMyProfileView ? getUserAccount(state) : getCurrentUser(state)));
    const queryObject = qs.parse(location.search, {ignoreQueryPrefix: true, comma: true});
    const page = queryObject.page ? +queryObject.page : 1;
    const questions = useSelector(state => questionsSelectors.getUserAnsweredQuestions(state));
    const totalRecords = useSelector(state => state.questions.totalCount);
    const totalPages = calculateTotalPagesNumber(totalRecords, 10);
    const handlePagination = (event, value) => {
        const query = qs.stringify({page: value});
        history.push({
            pathname: location.pathname,
            search: query,
        });
    };
    const goBack = () => {
        history.goBack();
    };

    return (
        <AppLayoutView>
            {user && questions ? (
                <Fragment>
                    <AtheneRow>
                        <Grid component="div" item xs={8}>
                            <PageTitleSection title={`${isMyProfileView ? 'Your profile' : `${user.name}'s profile`}`} goBackHandler={goBack} />
                        </Grid>
                    </AtheneRow>
                    <ProfileHeader user={user} />
                    <ProfileQuestionsPanel
                        tabs={isMyProfileView ? myProfileQuestionsTabs : createUserQuestionsTabs(user.id)}
                        handlePagination={handlePagination}
                        page={page}
                        totalPages={totalPages}
                        questions={questions}
                    />
                </Fragment>
            ) : (
                <Box py={30}>
                    <Typography component="h2" variant="h1" color="textPrimary" align="center">Loading...</Typography>
                </Box>
            )}
        </AppLayoutView>
    );
};

export default ProfileAnsweredQuestionsView;
