import {call, put} from 'redux-saga/effects';
import * as authActionTypes from '../authActionTypes';
import * as authApi from '../auth-services/authApi';

const getUserInfo = function* getUserInfo(params) {
    try {
        yield put({
            type: authActionTypes.GET_USER_INFO_INFO_REQUEST_SENT,
            payload: params,
        });

        const response = yield call(authApi.fetchUserInfo, params);
        yield put({
            type: authActionTypes.GET_USER_INFO_REQUEST_SUCCEEDED,
            payload: {userDTO: response},
        });
    } catch (error) {
        yield put({type: authActionTypes.GET_USER_INFO_REQUEST_FAILED, error: true, payload: error});
    }
};

export default getUserInfo;
