import {fork, take, put} from 'redux-saga/effects';
import getQuestionLeaders from './getQuestionLeaders';
import * as usersActionTypes from '../usersActionTypes';

const getQuestionLeadersFlow = function* () {
    yield put({
        type: usersActionTypes.RESET_LEADERS_STATUS,
        payload: {leadersIds: 'questionLeadersIds'},
    });

    yield fork(getQuestionLeaders, {usersTypes: 'question-leaders'});

    const actionResult = yield take([
        usersActionTypes.GET_QUESTION_LEADERS_REQUEST_SUCCEEDED,
        usersActionTypes.GET_QUESTION_LEADERS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: usersActionTypes.STORE_QUESTION_LEADERS,
            payload: {usersData: actionResult.payload.response},
        });
    }
};

export default getQuestionLeadersFlow;
