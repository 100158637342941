import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles, withStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const CustomPagination = withStyles(theme => ({
    root: {
        '& .MuiPaginationItem-root': {
            fontSize: '16px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.yellow[500]}`,
        },
    },
}))(Pagination);

const AthenePagination = props => {
    const styles = useStyles();
    return (
        <CustomPagination
            {...props}
            className={styles.root}
        />
    );
};

export default AthenePagination;
