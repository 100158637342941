import config from '../../config';
import handleResponse from './handleResponse';
import {getAccessToken} from '../../application/lib/storeService';

export const getUsers = params => {
    const accessToken = getAccessToken();

    return fetch(
        `${config.API_URL}${config.ENDPOINTS.USERS}/${params.usersTypes}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};


export const getUser = params => {
    const accessToken = getAccessToken();

    return fetch(
        `${config.API_URL}${config.ENDPOINTS.USERS}/${params.userId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
