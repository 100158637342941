import React, {Fragment, useState, useEffect, useCallback} from 'react';
import qs from 'qs';
import {useHistory, matchPath} from 'react-router';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash.debounce';
import {Grid, AppBar, Paper, Typography, makeStyles, Link, Box, OutlinedInput, InputAdornment} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import config from '../../config';
import routePaths from '../../routePaths';
import {PrimaryTextButton, SecondaryTextButton} from '../../ui-components/Button';
import {CustomTextLink} from '../../ui-components/Links';
import {storeAlreadyVisited, getAlreadyVisited} from '../lib/storeService';
import MenuDropDown from '../../ui-components/MenuDropDown';
import * as authActionTypes from '../../auth/authActionTypes';
import * as questionsActionTypes from '../../questions/questionsActionTypes';
import {getSearchedByTitleAutocompleteQuestions} from '../../questions/questionsSelectors';
import AtheneRow from '../../ui-components/AtheneRow/AtheneRow';

import resolveRoute from '../lib/resolveRoute';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    justifyEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        alignItems: 'center',
    },
    appBar: {
        'height': '130px',
        'backgroundColor': '#ffffff',
        '& > *': {
            height: '100%',
        },
        'display': 'flex',
        'alignItems': 'center',
        'boxShadow': '4px 4px 25px rgba(0, 0, 0, 0.15)',
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(3),
        color: theme.palette.primary.contrastText,
    },
    menuWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '20px',

    },
    searchInputField: {
        'padding': '6px 8px 4px 16px',
        'width': '100%',
    },
    inputIconPrimary: {
        color: theme.palette.primary.main,
    },
    inputIconSecondary: {
        color: theme.palette.text.secondary,
    },
    avatar: {
        flex: '0 0 32px',
        height: '32px',
    },
    welcome: {
        alignSelf: 'flex-start',
    },
    activeLink: {
        color: theme.palette.primary.main,
    },
    menuLinksWrapper: {
        listStyleType: 'none',
    },
    option: {
        '&:hover': {
            backgroundColor: 'rgba(27, 117, 187, 0.05)',
        },
        '&[data-focus="true"]': {
            backgroundColor: 'rgba(27, 117, 187, 0.05)',
        },
    },
}));


const Header = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();
    const user = useSelector(state => state.auth.userAccount);
    const questions = useSelector(state => getSearchedByTitleAutocompleteQuestions(state));
    const isUserSignedIn = useSelector(state => state.auth.isUserSignedIn);
    const [isMassageShown, setShowingMessage] = useState(!getAlreadyVisited() && isUserSignedIn);


    const [searchTerm, setSearchTerm] = useState('');
    const [shouldCallSearchApi, setShouldCallSearchApi] = useState(false);
    const [isAutocompleteFocus, setAutocompleteFocus] = useState(false);

    const doSearch = () => {
        if (shouldCallSearchApi) {
            dispatch({
                type: questionsActionTypes.START_SEARCHING_BY_TITLE_FLOW,
                payload: {title: searchTerm},
            });
        }
        setShouldCallSearchApi(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedSearch = useCallback(debounce(doSearch, 1000), [debounce, searchTerm, dispatch]);


    useEffect(() => {
        delayedSearch();

        return delayedSearch.cancel;
    }, [dispatch, delayedSearch, searchTerm]);


    const storeMessageState = () => {
        setShowingMessage(false);
        storeAlreadyVisited();
    };

    const onInputKeyDown = event => {
        const {key} = event;
        if (key !== 'Enter' || !searchTerm) {
            return;
        }
        event.preventDefault();

        history.push({
            pathname: routePaths.SEARCHED_QUESTIONS,
            search: qs.stringify({title: searchTerm}),
            title: searchTerm,
        });
    };

    const redirectToQuestion = id => {
        history.push({
            pathname: resolveRoute(routePaths.QUESTION, {questionId: id}).pathname,
        });
    };

    return (
        <Fragment>
            <AppBar position="static" className={styles.appBar}>
                <AtheneRow>
                    <Grid component="div" item xs={2} className={styles.root}>
                        {isUserSignedIn ? (
                            <NavLink to={routePaths.LATEST_QUESTIONS}>
                                <img src="/assets/images/athene-logo.png" alt="" />
                            </NavLink>
                        ) : (
                            <img src="/assets/images/athene-logo.png" alt="" />
                        )}
                    </Grid>
                    {isUserSignedIn && (
                        <Fragment>
                            <Grid component="div" item xs={3} className={styles.root}>
                                <Typography component="div" variant="body1" className={styles.menuWrapper}>
                                    <Link
                                        component={CustomTextLink}
                                        to={routePaths.MY_OPEN_QUESTIONS}
                                        variant="body1"
                                        color="textSecondary"
                                        underline="none"
                                        activeClassName={styles.activeLink}
                                        isActive={(match, location) => {
                                            if (match && match.isExact) return true;

                                            return !!matchPath(location.pathname, {
                                                path: [
                                                    routePaths.MY_LAST_VIEWED_QUESTIONS,
                                                    routePaths.MY_ANSWERED_QUESTIONS,
                                                ],
                                            });
                                        }}
                                    >
                                        My QA
                                    </Link>
                                    <Link
                                        component={CustomTextLink}
                                        to={routePaths.LEADER_BOARD}
                                        variant="body1"
                                        color="textSecondary"
                                        underline="none"
                                        activeClassName={styles.activeLink}
                                    >
                                        Leaderboard
                                    </Link>
                                    <Link
                                        component={CustomTextLink}
                                        to={routePaths.TAGS}
                                        variant="body1"
                                        color="textSecondary"
                                        underline="none"
                                        activeClassName={styles.activeLink}
                                    >
                                        Tags
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid component="div" item xs={3} className={styles.root}>
                                <Box component="div" className={styles.justifyEnd}>
                                    <Autocomplete
                                        renderInput={params => (
                                            <OutlinedInput
                                                ref={params.InputProps.ref}
                                                inputProps={params.inputProps}
                                                classes={{input: styles.searchInputField}}
                                                size="small"
                                                endAdornment={(
                                                    <InputAdornment component="span" position="end">
                                                        <SearchIcon
                                                            component="svg"
                                                            className={isAutocompleteFocus
                                                                ? styles.inputIconPrimary : styles.inputIconSecondary}
                                                        />
                                                    </InputAdornment>
                                                )}
                                            />
                                        )}
                                        onFocus={() => setAutocompleteFocus(true)}
                                        onBlur={() => setAutocompleteFocus(false)}
                                        onClose={() => setSearchTerm('')}
                                        options={questions || []}
                                        getOptionLabel={option => option.title}
                                        onInputChange={e => setSearchTerm(e.target.value)}
                                        style={{width: '100%'}}
                                        classes={{option: styles.option}}
                                        onKeyDown={onInputKeyDown}
                                        onChange={(e, val) => {
                                            if (val) {
                                                redirectToQuestion(val.id);
                                            }
                                        }}
                                    />
                                    <Box component="div" ml={2} className={styles.avatar}>
                                        <MenuDropDown
                                            subLinks={[
                                                {title: 'Profile', to: routePaths.PROFILE_ANSWERED},
                                                {title: 'Bookmarks', to: routePaths.BOOKMARKS},
                                            ]}
                                            isTextLink={false}
                                            position="right"
                                            initials={user.initials}
                                            onLogoutClick={() => dispatch({type: authActionTypes.END_USER_SESSION})}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Fragment>
                    )}
                    {!isUserSignedIn && (
                        <Fragment>
                            <Grid component="div" item xs={5} className={styles.root} />
                            <Grid component="div" item xs={1} className={styles.root}>
                                <PrimaryTextButton
                                    startIcon={<KeyboardTabIcon />}
                                    onClick={() => {
                                        window.location.href = `${config.API_URL}${config.ENDPOINTS.LOGIN}?redirectUrl=${config.REDIRECTION_URL_ORIGIN}${routePaths.OAUTH}`;
                                    }}
                                >
                                    Login
                                </PrimaryTextButton>
                            </Grid>
                        </Fragment>
                    )}
                </AtheneRow>
            </AppBar>
            {isMassageShown && (
                <Paper elevation={3} className={styles.paper}>
                    <AtheneRow>
                        <Grid component="div" item xs={1} className={styles.welcome}>
                            <Typography component="h3" variant="h3" color="inherit">
                                Welcome!
                            </Typography>
                        </Grid>
                        <Grid component="div" item xs={7}>
                            <Typography component="p" variant="body1" color="inherit">
                                Athene is a project created specifically for making the life of Computer Rock employees
                                easier. It is a question-answers based project. Feel free to ask any questions you have
                                regarding company policy, code and project assistance as well as to answer someone
                                else&apos;s question.
                            </Typography>
                        </Grid>
                    </AtheneRow>
                    <AtheneRow>
                        <Grid component="div" item xs={8} className={styles.justifyEnd}>
                            <SecondaryTextButton onClick={storeMessageState}>
                                Got it!
                            </SecondaryTextButton>
                        </Grid>
                    </AtheneRow>
                </Paper>
            )}
        </Fragment>
    );
};

export default Header;
