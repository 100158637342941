import store from 'store';

export const localStorageKeys = {
    ALREADY_VISITED: 'already_visited',
};

export const storeAlreadyVisited = () => {
    store.set(localStorageKeys.ALREADY_VISITED, true);
};

export const getAlreadyVisited = () => {
    return store.get(localStorageKeys.ALREADY_VISITED || false);
};

export const storeAccessToken = token => {
    store.set('access-token', token);
};

export const getAccessToken = () => {
    return store.get('access-token' || null);
};
