import {call, put} from 'redux-saga/effects';
import * as usersActionTypes from '../usersActionTypes';
import * as userApi from '../users-api-services/usersApi';

const getUser = function* (params) {
    try {
        yield put({
            type: usersActionTypes.GET_USER_REQUEST_SENT,
            payload: params,
        });

        const response = yield call(userApi.getUser, params);
        yield put({
            type: usersActionTypes.GET_USER_REQUEST_SUCCEEDED,
            payload: {response},
        });
    } catch (error) {
        yield put({type: usersActionTypes.GET_USER_REQUEST_FAILED, error: true, payload: error});
    }
};

export default getUser;
