export const GET_ANSWER_LEADERS_REQUEST_SENT = 'GET_ANSWER_LEADERS_REQUEST_SENT';
export const GET_ANSWER_LEADERS_REQUEST_SUCCEEDED = 'GET_ANSWER_LEADERS_REQUEST_SUCCEEDED';
export const GET_ANSWER_LEADERS_REQUEST_FAILED = 'GET_ANSWER_LEADERS_REQUEST_FAILED';

export const GET_QUESTION_LEADERS_REQUEST_SENT = 'GET_QUESTION_LEADERS_REQUEST_SENT';
export const GET_QUESTION_LEADERS_REQUEST_SUCCEEDED = 'GET_QUESTION_LEADERS_REQUEST_SUCCEEDED';
export const GET_QUESTION_LEADERS_REQUEST_FAILED = 'GET_QUESTION_LEADERS_REQUEST_FAILED';

export const GET_USER_REQUEST_SENT = 'GET_USER_REQUEST_SENT';
export const GET_USER_REQUEST_SUCCEEDED = 'GET_USER_REQUEST_SUCCEEDED';
export const GET_USER_REQUEST_FAILED = 'GET_USER_REQUEST_FAILED';

export const RESET_LEADERS_STATUS = 'RESET_LEADERS_STATUS';

export const STORE_ANSWER_LEADERS = 'STORE_ANSWER_LEADERS';
export const STORE_QUESTION_LEADERS = 'STORE_QUESTION_LEADERS';
export const STORE_CURRENT_USER = 'STORE_CURRENT_USER';
