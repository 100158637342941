import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Grid, Typography} from '@material-ui/core';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';
import AskQuestion from '../questions/view-elements/AskQuestion';
import AtheneDivider from '../ui-components/AtheneDivider/AtheneDivider';
import LeaderBoardItem from './view-elements/LeaderBoardItem';
import * as usersSelectors from './usersSelectors';
import AppLayoutView from '../application/AppLayoutView';


const LeaderBoardView = () => {
    const answerLeaders = useSelector(state => usersSelectors.getAnswerLeaders(state));
    const questionLeaders = useSelector(state => usersSelectors.getQuestionLeaders(state));
    return (
        <AppLayoutView>
            <Box mb={7}>
                <AtheneRow>
                    <AskQuestion />
                </AtheneRow>
            </Box>
            <AtheneRow alignment="flex-start">
                <Grid component="div" item xs={4}>
                    <Typography component="h2" variant="h2" color="textPrimary">Most Questions</Typography>
                    <AtheneDivider />
                    {questionLeaders ? (
                        <Box component="div">
                            {questionLeaders.length ? questionLeaders.map((user, idx) => {
                                return (
                                    <LeaderBoardItem key={`${user.id}-q-${user.name}`} user={user} index={idx + 1} />
                                );
                            }) : (
                                <Typography
                                    component="h3"
                                    variant="h3"
                                    color="textPrimary"
                                >
                                    There are no users
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Typography component="h3" variant="h3" color="textPrimary">Loading...</Typography>
                    )}
                </Grid>
                <Grid component="div" item xs={4}>
                    <Typography component="h2" variant="h2" color="textPrimary">Most Answers</Typography>
                    <AtheneDivider />
                    {answerLeaders ? (
                        <Box component="div">
                            {answerLeaders.length ? answerLeaders.map((user, idx) => {
                                return (
                                    <LeaderBoardItem
                                        key={`${user.id}-a-${user.name}`}
                                        user={user}
                                        index={idx + 1}
                                        isMostAnswerItem
                                    />
                                );
                            }) : (
                                <Typography
                                    component="h3"
                                    variant="h3"
                                    color="textPrimary"
                                >
                                    There are no users
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Typography component="h3" variant="h3" color="textPrimary">Loading...</Typography>
                    )}
                </Grid>
            </AtheneRow>
        </AppLayoutView>
    );
};

export default LeaderBoardView;
