import {fork, all, call} from 'redux-saga/effects';
import routes from './routes';
import {connectedRouterSaga} from './application/connectedRouterSagas';
import authWatcher from './auth/authWatcher';
import questionsWatcher from './questions/questionsWatcher';

/**
 * Root saga
 */
const rootSaga = function* () {
    yield all([
        fork(authWatcher),
        fork(questionsWatcher),

        // connected router saga
        call(connectedRouterSaga, routes),
    ]);
};

export default rootSaga;
