import {getDataByIds} from '../application/lib/selectors';

export const getAnswerUsers = state => state.users.answerLeaders.toJS();
export const getQuestionsUsers = state => state.users.questionLeaders.toJS();
export const getCurrentUser = state => state.users.currentUser;

const getAnswersLeadersIds = state => state.users.answerLeadersIds;
const getQuestionsLeadersIds = state => state.users.questionLeadersIds;

export const getAnswerLeaders = getDataByIds(getAnswerUsers, getAnswersLeadersIds);
export const getQuestionLeaders = getDataByIds(getQuestionsUsers, getQuestionsLeadersIds);

