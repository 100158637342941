import config from '../../config';
import handleResponse from './handleResponse';
import {getAccessToken} from '../../application/lib/storeService';

export const getTags = () => {
    const accessToken = getAccessToken();

    return fetch(
        `${config.API_URL}${config.ENDPOINTS.TAGS}/grouped`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};
