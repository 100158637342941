import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        width: '100%',
        padding: '8px 0 8px 16px',
    },
}));

const CustomButtonLink = props => {
    const styles = useStyles();
    return (
        <Button color="primary" component={Link} to={props.to} className={styles.root}>
            <Typography component="p" variant="body1">
                {props.title}
            </Typography>
        </Button>
    );
};

CustomButtonLink.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default CustomButtonLink;
