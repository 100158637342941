import {call, put} from 'redux-saga/effects';
import * as usersActionTypes from '../usersActionTypes';
import * as questionApi from '../users-api-services/usersApi';

const getQuestionLeaders = function* (params) {
    try {
        yield put({
            type: usersActionTypes.GET_QUESTION_LEADERS_REQUEST_SENT,
            payload: params,
        });

        const response = yield call(questionApi.getUsers, params);
        yield put({
            type: usersActionTypes.GET_QUESTION_LEADERS_REQUEST_SUCCEEDED,
            payload: {response},
        });
    } catch (error) {
        yield put({type: usersActionTypes.GET_QUESTION_LEADERS_REQUEST_FAILED, error: true, payload: error});
    }
};

export default getQuestionLeaders;
