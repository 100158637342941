import {fork, take, put, select, call, spawn} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import getUserInfo from './sagas/getUserInfo';
import * as authActionTypes from './authActionTypes';
import {END_SIDE_EFFECTS_RUNNING} from '../application/connectedRouterSagas';
import * as selectors from './authSelectors';
import {storeAccessToken, getAccessToken} from '../application/lib/storeService';
import routePaths from '../routePaths';
import {userState} from './constants/userState';
import userSessionFlow from './sagas/userSessionFlow';

export const loadOAuth2AccessToken = function* loadOAuth2AccessToken({payload}) {
    const {token: accessToken} = payload.location.query;
    storeAccessToken(accessToken);
    yield fork(getUserInfo, {accessToken});

    const actionResults = yield take([
        authActionTypes.GET_USER_INFO_REQUEST_SUCCEEDED,
        authActionTypes.GET_USER_INFO_REQUEST_FAILED,
    ]);

    if (!actionResults.error) {
        const {userDTO} = actionResults.payload;
        yield put({
            type: authActionTypes.STORE_USER,
            payload: {userDTO, isUserSignedIn: true},
        });
        yield put(push(routePaths.LATEST_QUESTIONS));
        yield spawn(userSessionFlow, {accessToken});
    }
};

export const loadUserSession = function* () {
    const isUserSignedIn = yield select(selectors.getUserSignedInStatus);
    if (isUserSignedIn) return null;

    const accessToken = yield call(getAccessToken);

    if (accessToken) {
        const jwt = jwtDecode(accessToken);
        const tokenExpTime = moment.unix(jwt.exp);
        if (moment() > tokenExpTime) {
            storeAccessToken(null);
            yield put({
                type: authActionTypes.STORE_USER,
                payload: {userDTO: null, isUserSignedIn: false},
            });
            // yield put(push(routePaths.SIGN_IN));
            return;
        }

        yield fork(getUserInfo, {accessToken});

        const actionResults = yield take([
            authActionTypes.GET_USER_INFO_REQUEST_SUCCEEDED,
            authActionTypes.GET_USER_INFO_REQUEST_FAILED,
        ]);

        if (!actionResults.error) {
            const {userDTO} = actionResults.payload;
            yield put({
                type: authActionTypes.STORE_USER,
                payload: {userDTO, isUserSignedIn: true},
            });
            yield spawn(userSessionFlow, {accessToken});
            return;
        }
    }
};

export const accessControl = function* ({payload}) {
    const isUserSignedIn = yield select(selectors.getUserSignedInStatus);
    const currentUserState = isUserSignedIn ? userState.USER_SIGNED_IN : userState.USER_SIGNED_OUT;

    const {allowedState, redirectTo} = payload;
    if (!redirectTo) return;

    yield put({
        type: authActionTypes.ACCESS_CONTROL_INITIATED,
        payload: {currentUserState, allowedState, redirectTo},
    });

    if (currentUserState !== allowedState) {
        yield put(push(redirectTo));
        return END_SIDE_EFFECTS_RUNNING;
    }
};

