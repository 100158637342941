import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles(theme => ({
    root: {

        '& .MuiInput-underline:before': {
            borderBottom: `2px solid ${theme.palette.grey[300]}`,
        },
        '& .MuiInputBase-input': {
            color: theme.palette.grey[500],
            opacity: 1,
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '33px',
            padding: '15px 0',
        },
        '& .MuiInputBase-input::-webkit-input-placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '33px',
        },
    },
}))(TextField);


const QuestionInput = props => {
    return (
        <CssTextField {...props} />
    );
};

export default QuestionInput;
