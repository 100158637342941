import {all} from 'redux-saga/effects';


/**
 * Auth watcher saga
 */
const authWatcher = function* authWatcher() {
    yield all([
    ]);
};

export default authWatcher;
