import * as actionTypes from './authActionTypes';
import UserAccount from './records/UserAccount';

const initialState = {
    userAccount: null,
    isUserSignedIn: false,
};

/**
 * Auth reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_USER: {
            const {userDTO, isUserSignedIn} = action.payload;

            state = {
                ...state,
                userAccount: userDTO ? new UserAccount({}).fromDTO(userDTO) : null,
                isUserSignedIn,
            };
            break;
        }
        default:
            // no-op
    }
    return state;
};

export default authReducer;
