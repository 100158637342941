import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Box, SvgIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {blue} from '../theme/colors';

const useStyles = makeStyles({
    root: {
        width: '32px',
        height: '32px',
        backgroundColor: blue['400'],
        fontWeight: 700,
        fontSize: '16px',
    },
    rootBig: {
        width: '222px',
        height: '222px',
        backgroundColor: blue['400'],
        fontWeight: 700,
        fontSize: '111px',
    },
    cursor: {
        cursor: 'pointer',
    },
    avatarWrapper: {
        position: 'relative',
    },
    leaderIcon: {
        position: 'absolute',
        top: '-9px',
        width: '22px',
        height: '15px',
    },
});

const LeaderIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M2.26728 14.8859L0 4.12207L5.7171 7.0056L9.78527 2.0609L15.5024 4.94443L19.5705 -0.000261128L21.8378 10.7635L2.26728 14.8859Z" fill="#D6DF23" />
        </SvgIcon>
    );
};

const AtheneAvatar = props => {
    const styles = useStyles();
    return (
        <Box component="div" className={styles.avatarWrapper}>
            <Avatar
                classes={{root: props.variant === 'small' ? styles.root : styles.rootBig}}
                className={props.isLink ? styles.cursor : ''}
                component="div"
                onClick={props.onClick}
            >
                {props.initials}
            </Avatar>
            {props.isLeader && (
                <LeaderIcon className={styles.leaderIcon} width="22" height="15" viewBox="0 0 22 15" />
            )}
        </Box>
    );
};

AtheneAvatar.propTypes = {
    initials: PropTypes.string.isRequired,
    isLink: PropTypes.bool,
    onClick: PropTypes.func,
    isLeader: PropTypes.bool,
    variant: PropTypes.oneOf(['big', 'small']),
};

AtheneAvatar.defaultProps = {
    isLink: false,
    onClick: null,
    isLeader: false,
    variant: 'small',
};

export default AtheneAvatar;
