import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        height: '60px',
        display: 'flex',
    },
}));

const AtheneTabs = props => {
    const styles = useStyles();
    return (
        <Grid component="div" item xs={8}>
            <Box className={styles.root} mb={2}>
                {props.children}
            </Box>
        </Grid>
    );
};

export default AtheneTabs;
