import {fork, take, put, select} from 'redux-saga/effects';
import getQuestions from './sagas/getQuestions';
import * as questionsActionTypes from './questionsActionTypes';
import * as usersActionTypes from '../users/usersActionTypes';
import {getUserAccount} from '../auth/authSelectors';
import getUser from '../users/sagas/getUser';

const questionTypes = {
    LATEST: 'latest',
    UNANSWERED: 'unanswered',
    MOST_VIEWED: 'most-viewed',
    USER_OPEN: 'user-open',
    USER_ANSWERED: 'user-answered',
    MY_LAST_VIEWED: 'my-last-viewed',
    BOOKMARKS: 'bookmarks',
    SEARCHED: 'search-autocomplete',
};

export const loadLatestQuestions = function* (params) {
    const questionsType = questionTypes.LATEST;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_LATEST_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadUnansweredQuestions = function* (params) {
    const questionsType = questionTypes.UNANSWERED;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_UNANSWERED_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadMostViewedQuestions = function* (params) {
    const questionsType = questionTypes.MOST_VIEWED;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_MOST_VIEWED_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};


export const loadUserOpenQuestions = function* (params) {
    const questionsType = questionTypes.USER_OPEN;
    let id = params.payload.match.params.userId;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    if (!id) {
        const userAccount = yield select(getUserAccount);
        id = userAccount.id;
    } else {
        yield put({
            type: usersActionTypes.STORE_CURRENT_USER,
            payload: {userData: null},
        });

        yield fork(getUser, {userId: id});
        const actionResultUser = yield take([
            usersActionTypes.GET_USER_REQUEST_SUCCEEDED,
            usersActionTypes.GET_USER_REQUEST_FAILED,
        ]);

        if (actionResultUser.error) {
            return;
        }
        yield put({
            type: usersActionTypes.STORE_CURRENT_USER,
            payload: {userData: actionResultUser.payload.response},
        });
    }

    yield fork(getQuestions, {questionsType, search, userId: id});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_MY_OPEN_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadUserAnsweredQuestions = function* (params) {
    const questionsType = questionTypes.USER_ANSWERED;
    const {search} = params.payload.location;
    let id = params.payload.match.params.userId;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    if (!id) {
        const userAccount = yield select(getUserAccount);
        id = userAccount.id;
    } else {
        yield put({
            type: usersActionTypes.STORE_CURRENT_USER,
            payload: {userData: null},
        });

        yield fork(getUser, {userId: id});
        const actionResultUser = yield take([
            usersActionTypes.GET_USER_REQUEST_SUCCEEDED,
            usersActionTypes.GET_USER_REQUEST_FAILED,
        ]);

        if (actionResultUser.error) {
            return;
        }
        yield put({
            type: usersActionTypes.STORE_CURRENT_USER,
            payload: {userData: actionResultUser.payload.response},
        });
    }


    yield fork(getQuestions, {questionsType, search, userId: id});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_MY_ANSWERED_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadMyLastViewedQuestions = function* (params) {
    const questionsType = questionTypes.MY_LAST_VIEWED;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_MY_LATEST_VIEWED_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadBookmarkedQuestions = function* (params) {
    const questionsType = questionTypes.BOOKMARKS;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_BOOKMARKED_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};

export const loadSearchedByTitleQuestions = function* (params) {
    const questionsType = questionTypes.SEARCHED;
    const {search} = params.payload.location;

    yield put({type: questionsActionTypes.RESET_QUESTIONS});

    yield fork(getQuestions, {questionsType, search});

    const actionResult = yield take([
        questionsActionTypes.GET_QUESTIONS_REQUEST_SUCCEEDED,
        questionsActionTypes.GET_QUESTIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        yield put({
            type: questionsActionTypes.STORE_SEARCHED_BY_TITLE_QUESTIONS,
            payload: {questionsData: actionResult.payload.response},
        });
    }
};
