export default {
    SIGN_IN: '/sign-in',
    OAUTH: '/sign-in/oauth',
    MY_OPEN_QUESTIONS: '/my-open-questions',
    MY_ANSWERED_QUESTIONS: '/my-answered-questions',
    MY_LAST_VIEWED_QUESTIONS: '/my-last-viewed-questions',
    MOST_VIEWED_QUESTIONS: '/most-viewed-questions',
    LATEST_QUESTIONS: '/',
    UNANSWERED_QUESTIONS: '/unanswered-questions',
    LEADER_BOARD: '/leader-board',
    QUESTIONS: '/questions',
    MY_ANSWERS: '/my-answers',
    MY_QUESTIONS: '/my-questions',
    ANSWERS: '/answers',
    PROFILE_ANSWERED: '/profile-answered/',
    PROFILE_OPEN: '/profile-open/',
    USER_ANSWERED: '/user-answered/:userId',
    USER_OPEN: '/user-open/:userId',
    BOOKMARKS: '/bookmarks',
    TAGS: '/tags',
    QUESTIONS_TAGS: '/questions/tags',
    QUESTION: '/question/:questionId',
    SEARCHED_QUESTIONS: '/searched-questions',
};
