import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Box, Button, Typography, makeStyles, withStyles} from '@material-ui/core';
import routePaths from '../../routePaths';

const CustomButton = withStyles(theme => ({
    root: {
        'boxShadow': 'none',
        'position': 'relative',
        'backgroundColor': theme.palette.yellow[500],
        '&:hover': {
            'backgroundColor': theme.palette.yellow[600],
            'boxShadow': theme.shadows[2],
        },
        '&:active': {
            backgroundColor: theme.palette.yellow[600],
        },
        '&:focus': {
            'outline': 'none',
            'boxShadow': 'none',
        },
        '& .MuiButton-label': {
            backgroundColor: 'inherit',
        },
        '& .MuiTouchRipple-root span': {
            '&:focus': {
                outline: 'none',
            },
        },
    },
}))(Button);

const useStyles = makeStyles({
    tagWrapper: {
        textDecoration: 'none',
    },
    tagTitle: {
        color: '#ffffff',
        position: 'relative',
        zIndex: 1,
        textDecoration: 'none',
    },
    smallTag: {
        padding: '1px 8px',
    },
    bigTag: {
        'padding': '5px 8px',
        'marginLeft': '16px',
        'filter': 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
        '-webkit-filter': 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
        '&:hover': {
            'boxShadow': 'none',
        },
    },
    rect: {
        position: 'absolute',
        transform: 'translateX(-50%) rotate(45deg)',
        backgroundColor: 'inherit',
        boxShadow: 'inherit',
        zIndex: 0,
    },
    rectSmall: {
        width: '12px',
        height: '12px',
        top: '2px',
        left: '2px',
    },
    rectBig: {
        width: '22px',
        height: '22px',
        top: '5px',
        left: '2px',
    },
});

const Tag = props => {
    const styles = useStyles();
    return (
        <Box
            component={Link}
            to={`${routePaths.QUESTIONS_TAGS}?searchedTags=${props.name}`}
            className={styles.tagWrapper}
        >
            <CustomButton className={props.variant === 'small' ? styles.smallTag : styles.bigTag}>
                <Box
                    component="div"
                    className={`${styles.rect} ${props.variant === 'small' ? styles.rectSmall : styles.rectBig}`}
                />
                <Typography
                    component="p"
                    variant={props.variant === 'small' ? 'overline' : 'button'}
                    className={styles.tagTitle}
                >
                    {props.name}
                </Typography>
            </CustomButton>
        </Box>

    );
};

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['big', 'small']),
};

Tag.defaultProps = {
    variant: 'small',
};

export default Tag;
