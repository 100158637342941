import config from '../../config';
import handleResponse from './handleResponse';

export const fetchUserInfo = params => {
    return fetch(
        `${config.API_URL}${config.ENDPOINTS.USER_INFO}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${params.accessToken}`,
            },
            method: 'GET',
        },
    )
        .then(handleResponse);
};

