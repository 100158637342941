import '@computerrock/react-app-polyfill/ie11';
import '@computerrock/react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'svgxuse'; // fixes IE10/11 issue when using external SVG
import {AppContainer} from 'react-hot-loader';
import {ConnectedRouter} from 'connected-react-router';
import {renderRoutes} from 'react-router-config';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './ui-components/theme/theme';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import rootSaga from './saga';
import routes from './routes';
import './index.scss';


const history = createBrowserHistory();

// initialize store
const store = configureStore({}, history);
store.runSaga(rootSaga);
// render app
// render application
ReactDOM.render(
    <AppContainer>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    {renderRoutes(routes)}
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    </AppContainer>,
    document.getElementById('root'),
);

// webpack hot module replacement for ./routes configuration,
// ./saga configuration and state is not hot reloaded but needs to
// be accepted so HMR can work properly
if (module.hot) {
    module.hot.accept(['./routes', './saga'], () => {
        ReactDOM.render(
            (
                <AppContainer>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            {renderRoutes(routes)}
                        </ConnectedRouter>
                    </Provider>
                </AppContainer>
            ),
            document.getElementById('root'),
        );
    });
}

// register service worker
serviceWorker.register({
    onUpdate: () => {
        // reload on new content
        window.location.reload();
    },
});
