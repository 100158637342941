import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Grid, Box, Typography, makeStyles} from '@material-ui/core';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import AtheneAvatar from '../../ui-components/AtheneAvatar/AtheneAvatar';
import Tag from '../../ui-components/Tag/Tag';
import resolveRoute from '../../application/lib/resolveRoute';
import routePaths from '../../routePaths';

const TIME_FORMAT = 'DD-MM-YYYY';

const useStyles = makeStyles(theme => ({
    metaDataWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    questionWrapper: {
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        paddingBottom: theme.spacing(2),
        textDecoration: 'none',
        display: 'block',
    },
    tagsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    questionContent: {
        width: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    metaDataContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
    },
}));

const QuestionRow = ({question}) => {
    const styles = useStyles();
    return (
        <Grid component="div" item xs={8}>
            <Box
                className={styles.questionWrapper}
                mb={3}
            >
                <Box component="div" className={styles.metaDataWrapper} mb={1}>
                    <AtheneAvatar initials={question.userInitials} />
                    <Box component="div" ml={1} className={styles.metaDataContent}>
                        <Box>
                            <Typography component="p" variant="caption" color="textSecondary">{question.userName}</Typography>
                            <Typography component="p" variant="caption" color="textSecondary">
                                <span>
                                    {`${question.createdAt.format(TIME_FORMAT)} `}
                                    &bull;
                                    {` ${question.answersCount} `}Answers
                                </span>
                            </Typography>
                        </Box>
                        {question.isBookmarkIconShown && question.isBookmarked && (
                            <Box>
                                <BookmarkOutlinedIcon component="svg" color="primary" />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Typography
                    component={Link}
                    to={resolveRoute(routePaths.QUESTION, {questionId: question.id})}
                    variant="h2"
                    color="textPrimary"
                    style={{textDecoration: 'none'}}
                >
                    {question.title}
                </Typography>
                <Typography
                    component="p"
                    variant="body1"
                    color="textSecondary"
                    className={styles.questionContent}
                >
                    {question.content}
                </Typography>
                <Box component="div" className={styles.tagsWrapper}>
                    {question.tags && question.tags.map(tag => {
                        return (
                            <Box component="div" ml={1.2} key={tag.name}>
                                <Tag name={tag.name} />
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Grid>
    );
};

QuestionRow.propTypes = {
    question: PropTypes.object.isRequired,
};

export default QuestionRow;
