import React from 'react';
import {Button, makeStyles} from '@material-ui/core/index';


const useStyles = makeStyles(theme => ({
    root: {
        'padding': '9px 32px',
        '&:hover': {
            background: theme.palette.blue[50],
        },
        '&$disabled': {
            'color': theme.palette.blue[100],
        },
    },
    disabled: {},
}));


const PrimaryTextButton = ({children, ...props}) => {
    const styles = useStyles();
    return (
        <Button
            variant="text"
            color="primary"
            className={styles.root}
            classes={{disabled: styles.disabled}}
            {...props}
        >
            {children}
        </Button>

    );
};

export default PrimaryTextButton;
