import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, makeStyles} from '@material-ui/core';
import AtheneDivider from '../../ui-components/AtheneDivider/AtheneDivider';

const useStyles = makeStyles(theme => ({
    titleSectionWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    goBack: {
        cursor: 'pointer',
        color: theme.palette.grey[500],
    },
}));

const PageTitleSection = ({title, goBackHandler}) => {
    const styles = useStyles();
    return (
        <Fragment>
            <Box className={styles.titleSectionWrapper}>
                <Typography component="h2" variant="h2" color="textPrimary">{title}</Typography>
                {goBackHandler && (
                    <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                        onClick={goBackHandler}
                        className={styles.goBack}
                    >
                        &larr; Back to
                    </Typography>
                )}
            </Box>
            <AtheneDivider />
        </Fragment>

    );
};

PageTitleSection.propTypes = {
    title: PropTypes.string,
    goBackHandler: PropTypes.func,
};

PageTitleSection.defaultProps = {
    title: '',
    goBackHandler: null,
};

export default PageTitleSection;
