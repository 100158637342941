import {Record} from 'immutable';
import moment from 'moment';
import {makeInitials} from '../../application/lib/index';

/**
 * Question definition
 *
 * @typedef {Immutable.Record} Question
 * @property {?string} id
 * @property {?string} title
 * @property {?string} content
 * @property {?string} userName
 * @property {?string} userNameId
 * @property {?string} userInitials
 * @property {?string} answers
 * @property {?number} answersCount
 * @property {?array} tags
 * @property {?string} createdAt
 * @property {?string} updatedAt
 * @property {?boolean} isBookmarkIconShown
 * @property {?boolean} isBookmarked
 */

/**
 * Question record
 *
 * @type {Question}
 */

export class Question extends Record({
    id: null,
    content: null,
    title: null,
    userName: null,
    userNameId: null,
    userInitials: null,
    answers: null,
    answersCount: null,
    tags: null,
    createdAt: null,
    updatedAt: null,
    isBookmarkIconShown: null,
    isBookmarked: null,
}) {
    fromDTO(questionDTO) {
        return new Question({
            ...this.toObject(),
            ...questionDTO,
            userName: questionDTO.userCreated.name,
            userNameId: questionDTO.userCreated.id,
            userInitials: makeInitials(questionDTO.userCreated.name),
            answersCount: questionDTO.answers.length,
            createdAt: moment(questionDTO.createdAt),
            updatedAt: moment(questionDTO.updatedAt),
        });
    }
}
