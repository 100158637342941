import React from 'react';
import {makeStyles, Divider} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '2px',
        backgroundColor: theme.palette.grey[300],
        margin: '5px 0',
    },
}));

const AtheneDivider = props => {
    const styles = useStyles();
    return (
        <Divider component="hr" {...props} className={styles.root} />
    );
};

export default AtheneDivider;
