export const GET_QUESTIONS_REQUEST_SENT = 'GET_QUESTIONS_REQUEST_SENT';
export const GET_QUESTIONS_REQUEST_SUCCEEDED = 'GET_QUESTIONS_REQUEST_SUCCEEDED';
export const GET_QUESTIONS_REQUEST_FAILED = 'GET_QUESTIONS_REQUEST_FAILED';

export const START_SEARCHING_BY_TITLE_FLOW = 'START_SEARCHING_BY_TITLE_FLOW';

export const RESET_QUESTIONS = 'RESET_QUESTIONS';

export const STORE_LATEST_QUESTIONS = 'STORE_LATEST_QUESTIONS';
export const STORE_UNANSWERED_QUESTIONS = 'STORE_UNANSWERED_QUESTIONS';
export const STORE_MOST_VIEWED_QUESTIONS = 'STORE_MOST_VIEWED_QUESTIONS';
export const STORE_MY_OPEN_QUESTIONS = 'STORE_MY_OPEN_QUESTIONS';
export const STORE_MY_ANSWERED_QUESTIONS = 'STORE_MY_ANSWERED_QUESTIONS';
export const STORE_MY_LATEST_VIEWED_QUESTIONS = 'STORE_MY_LATEST_VIEWED_QUESTIONS';
export const STORE_BOOKMARKED_QUESTIONS = 'STORE_BOOKMARKED_QUESTIONS';
export const STORE_SEARCHED_BY_TITLE_QUESTIONS = 'STORE_SEARCHED_BY_TITLE_QUESTIONS';
export const STORE_SEARCHED_BY_TITLE_AUTOCOMPLETE_QUESTIONS = 'STORE_SEARCHED_BY_TITLE_AUTOCOMPLETE_QUESTIONS';

