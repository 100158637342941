import Immutable from 'immutable';
import {Question} from './records/Question';
import * as questionsActionsTypes from './questionsActionTypes';

const initialState = {
    questions: Immutable.OrderedMap(),
    latestQuestionsIds: null,
    unansweredQuestionsIds: null,
    mostViewedQuestionsIds: null,
    userOpenQuestionsIds: null,
    userAnsweredQuestionsIds: null,
    myLastViewedQuestionsIds: null,
    bookmarkedQuestionsIds: null,
    searchedByTitleQuestionsIds: null,
    searchedByTitleAutocompleteQuestionsIds: null,
    totalCount: 0,
};

const setQuestionsData = (questionsDTO, meta) => {
    let questions = Immutable.OrderedMap();
    let questionsIds = [];
    questionsDTO.forEach(questionDTO => {
        let newQuestionDTO = questionDTO;
        if (meta === 'forBookmarksPage') {
            newQuestionDTO = {...newQuestionDTO, isBookmarkIconShown: true, isBookmarked: true};
        }
        const question = new Question({}).fromDTO(newQuestionDTO);
        if (question.id) {
            questions = questions.set(question.id, question);
            questionsIds = [...questionsIds, question.id];
        }
    });
    return {questions, questionsIds};
};


const questionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case questionsActionsTypes.RESET_QUESTIONS: {
            state = {
                ...state,
                latestQuestionsIds: null,
                unansweredQuestionsIds: null,
                mostViewedQuestionsIds: null,
                userOpenQuestionsIds: null,
                userAnsweredQuestionsIds: null,
                myLastViewedQuestionsIds: null,
                bookmarkedQuestionsIds: null,
                searchedByTitleQuestionsIds: null,
                searchedByTitleAutocompleteQuestionsIds: null,
                totalCount: 0,
            };
            break;
        }
        case questionsActionsTypes.STORE_LATEST_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                latestQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_UNANSWERED_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                unansweredQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_MOST_VIEWED_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                mostViewedQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_MY_OPEN_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                userOpenQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_MY_ANSWERED_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                userAnsweredQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_MY_LATEST_VIEWED_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                myLastViewedQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_BOOKMARKED_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, 'forBookmarksPage');
            state = {
                ...state,
                bookmarkedQuestionsIds: questionsIds,
                questions: state.questions.merge(questions),
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_SEARCHED_BY_TITLE_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO, totalCount} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                questions: state.questions.merge(questions),
                searchedByTitleQuestionsIds: questionsIds,
                totalCount,
            };
            break;
        }
        case questionsActionsTypes.STORE_SEARCHED_BY_TITLE_AUTOCOMPLETE_QUESTIONS: {
            const {questionsData} = action.payload;
            const {questions: questionsDTO} = questionsData;
            const {questions, questionsIds} = setQuestionsData(questionsDTO, null);
            state = {
                ...state,
                questions: state.questions.merge(questions),
                searchedByTitleAutocompleteQuestionsIds: questionsIds,
            };
            break;
        }
        default: // no options
    }
    return state;
};

export default questionsReducer;
