import React from 'react';
import {makeStyles, Grid, Box, Typography} from '@material-ui/core';
import config from '../config';
import routePaths from '../routePaths';
import AppLayoutView from '../application/AppLayoutView';
import {PrimaryButton} from '../ui-components/Button';
import AtheneRow from '../ui-components/AtheneRow/AtheneRow';


const useStyles = makeStyles({
    flexGrow: {
        flexGrow: 1,
        alignItems: 'center',
        display: 'flex',
    },
    backgroundDiv: {
        backgroundImage: 'url(/assets/images/athene-back.svg)',
        backgroundPosition: '100% 0px',
        backgroundRepeat: 'no-repeat',
        paddingTop: '100px',
        paddingBottom: '100px',
        flexGrow: 1,
    },
    marginBottom: {
        marginBottom: '24px',
    },
    contentDiv: {
        width: '62.5%',
        marginBottom: '48px',
    },
});

const SignInView = () => {
    const styles = useStyles();
    return (
        <AppLayoutView>
            <AtheneRow>
                <Grid component="div" item xs={8} className={styles.flexGrow}>
                    <Box component="div" className={styles.backgroundDiv}>
                        <Box component="div" className={styles.contentDiv}>
                            <Typography
                                component="h1"
                                variant="h1"
                                color="textPrimary"
                                gutterBottom
                                className={styles.marginBottom}
                            >
                                Welcome to Athene
                            </Typography>
                            <Typography
                                component="p"
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                                className={styles.marginBottom}
                            >
                                Athene is a project created specifically for making the life of Computer Rock employees
                                easier. It is a question-answers based project.
                            </Typography>
                            <Typography
                                component="p"
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                                className={styles.marginBottom}
                            >
                                Feel free to ask any questions you have regarding company policy, code
                                and project assistance as well as to answer someone else&apos;s question.
                            </Typography>
                        </Box>
                        <PrimaryButton onClick={() => {
                            window.location.href = `${config.API_URL}${config.ENDPOINTS.LOGIN}?redirectUrl=${config.REDIRECTION_URL_ORIGIN}${routePaths.OAUTH}`;
                        }}
                        >
                            Login
                        </PrimaryButton>
                    </Box>
                </Grid>
            </AtheneRow>
        </AppLayoutView>
    );
};

export default SignInView;
