import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Box, Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    bubbleWrapper: {
        'position': 'relative',
        'border': `2px solid ${theme.palette.primary.main}`,
        'borderRadius': '8px',
        'textDecoration': 'none',
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 0,
            height: 0,
        },
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 0,
            height: 0,
        },
    },
    bubbleWrapperRight: {
        'borderBottomRightRadius': '0px',
        '&::before': {
            right: '-2px',
            bottom: '-16px',
            border: '8px solid',
            borderColor: `${theme.palette.primary.main} ${theme.palette.primary.main} transparent transparent`,
        },
        '&::after': {
            right: 0,
            bottom: '-12px',
            border: '8px solid',
            borderColor: '#fff #fff transparent transparent',
        },
    },
    bubbleWrapperLeft: {
        'borderBottomLeftRadius': '0px',
        '&::before': {
            left: '-2px',
            bottom: '-16px',
            border: '8px solid',
            borderColor: `${theme.palette.primary.main} transparent transparent ${theme.palette.primary.main}`,
        },
        '&::after': {
            left: 0,
            bottom: '-12px',
            border: '8px solid',
            borderColor: '#fff transparent transparent #fff',
        },
    },
}));

const TalkBubble = props => {
    const styles = useStyles();
    return (
        <Box
            component={Link}
            to={props.to}
            px={3}
            py={1}
            className={`${styles.bubbleWrapper} ${props.variant === 'right'
                ? styles.bubbleWrapperRight : styles.bubbleWrapperLeft}`}
        >
            <Typography component="p" variant="h4" color="textPrimary">{props.text}</Typography>
        </Box>
    );
};

TalkBubble.propTypes = {
    text: PropTypes.string,
    variant: PropTypes.oneOf(['left', 'right']),
    to: PropTypes.string.isRequired,
};

TalkBubble.defaultProps = {
    text: '',
    variant: 'right',
};

export default TalkBubble;
