const {
    API_URL,
    REDIRECTION_URL_ORIGIN,
} = process.env;

export default {
    API_URL,
    REDIRECTION_URL_ORIGIN,
    ENDPOINTS: {
        LOGIN: '/login',
        USER_INFO: '/api/users/authenticated',
        QUESTIONS: '/api/questions',
        USERS: '/api/users',
        TAGS: '/api/tags',
    },
};
