import {call, put, take, delay, fork} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import * as authActionTypes from '../authActionTypes';
import {storeAccessToken} from '../../application/lib/storeService';

import routePaths from '../../routePaths';


const userSessionFlow = function* userSessionFlow({accessToken}) {
    yield put({
        type: authActionTypes.USER_SESSION_STARTED,
        payload: {accessToken},
    });
    const jwt = jwtDecode(accessToken);
    const tokenExpTime = moment.unix(jwt.exp);
    const delayTime = tokenExpTime.diff(moment());
    yield fork(endUserSession, {delayTime});

    yield take(authActionTypes.END_USER_SESSION);
    yield put({
        type: authActionTypes.STORE_USER,
        payload: {userDTO: null, isUserSignedIn: false},
    });
    yield call(storeAccessToken, null);
    yield put(push(routePaths.SIGN_IN));
};

export default userSessionFlow;


const endUserSession = function* ({delayTime}) {
    yield delay(delayTime - 5000);
    yield put({
        type: authActionTypes.END_USER_SESSION,
    });
};
