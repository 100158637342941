export const GET_USER_INFO_INFO_REQUEST_SENT = 'GET_USER_INFO_REQUEST_SENT';
export const GET_USER_INFO_REQUEST_SUCCEEDED = 'GET_USER_INFO_REQUEST_SUCCEEDED';
export const GET_USER_INFO_REQUEST_FAILED = 'GET_USER_INFO_REQUEST_FAILED';

export const STORE_USER = 'STORE_USER';

export const ACCESS_CONTROL_INITIATED = 'ACCESS_CONTROL_INITIATED';

export const USER_SESSION_STARTED = 'USER_SESSION_STARTED';
export const END_USER_SESSION = 'END_USER_SESSION';
